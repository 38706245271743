// Generated by Framer (f558e44)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IzKEAE26C"];

const serializationHash = "framer-BGD7i"

const variantClassNames = {IzKEAE26C: "framer-v-194o8a8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, R2n8DeQ9b: image ?? props.R2n8DeQ9b ?? {src: "https://framerusercontent.com/images/9N1FsMJmvuVVAvub8LUhb1y6L9E.webp"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, R2n8DeQ9b, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IzKEAE26C", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-194o8a8", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"IzKEAE26C"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 124.5, intrinsicWidth: 90, pixelHeight: 249, pixelWidth: 180, sizes: "1px", ...toResponsiveImage(R2n8DeQ9b)}} className={"framer-19vjg0g"} data-framer-name={"questionnaire"} layoutDependency={layoutDependency} layoutId={"VR9NIT7lo"}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-BGD7i[data-border=\"true\"]::after, .framer-BGD7i [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BGD7i.framer-1ncoq0, .framer-BGD7i .framer-1ncoq0 { display: block; }", ".framer-BGD7i.framer-194o8a8 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 125px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 91px; }", ".framer-BGD7i .framer-19vjg0g { aspect-ratio: 0.7228915662650602 / 1; flex: none; height: 100%; overflow: visible; position: relative; width: var(--framer-aspect-ratio-supported, 90px); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BGD7i.framer-194o8a8 { gap: 0px; } .framer-BGD7i.framer-194o8a8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-BGD7i.framer-194o8a8 > :first-child { margin-left: 0px; } .framer-BGD7i.framer-194o8a8 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 125
 * @framerIntrinsicWidth 91
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"R2n8DeQ9b":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framero6Cqote1e: React.ComponentType<Props> = withCSS(Component, css, "framer-BGD7i") as typeof Component;
export default Framero6Cqote1e;

Framero6Cqote1e.displayName = "Illustrations / Checklist";

Framero6Cqote1e.defaultProps = {height: 125, width: 91};

addPropertyControls(Framero6Cqote1e, {R2n8DeQ9b: {__defaultAssetReference: "data:framer/asset-reference,9N1FsMJmvuVVAvub8LUhb1y6L9E.webp?originalFilename=PNG+to+WEBP+checklist.webp&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framero6Cqote1e, [])